import { graphql, PageRendererProps } from "gatsby"
import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { Layout } from "../components/layout"
import { FadeLink } from "../components/link"
import { TagList } from "../components/tag-list"
import { Query, SitePageContext } from "../graphql-types"
import constants from "../utils/constants"
import { rhythm } from "../utils/typography"

interface Props extends PageRendererProps {
  pageContext: SitePageContext
  data: Query
}

const Link = styled(FadeLink)``

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${constants.colors.background};
    color: white;
  }
`

const Divider = styled.hr`
  margin-bottom: ${rhythm(1)};
`

const TaggedPostsTemplate = ({ pageContext, data, location }) => {
  const siteTitle = data.site!.siteMetadata!.title!
  const pageSlug = pageContext.slug
  const { tag, tags } = pageContext
  if (!tag) {
    return (
      <Layout location={location} slug={pageSlug || ''} title={siteTitle}>
        <GlobalStyle />
        <h1>All tags</h1>
        <TagList tags={tags}>
          All tags:
        </TagList>
      </Layout>
    )
  }
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout location={location} slug={pageSlug || ''} title={siteTitle}>
      <GlobalStyle />
      <h1>{tagHeader}</h1>
      <ul>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { description, title } = node.frontmatter
          const { excerpt } = node
          const blurb = excerpt || description
          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
              <p>
                {blurb}
              </p>
            </li>
          )
        })}
      </ul>
      <Divider />
      <TagList tags={tags}>
        All tags:
      </TagList>
    </Layout>
  )
}

export default TaggedPostsTemplate

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___sequencing], order: ASC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            backgroundColor
            description
            sequencing
            tags
            title
          }
        }
      }
    }
  }
`
